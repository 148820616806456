import { truncate } from "../number/truncate";

interface Options {
  precision?: number;
  baseAssetSymbol?: string;
  maximumFractionDigits?: number;
  fallback?: string;
}

export const formatCurrency = (
  value: number | undefined,
  options: Options = {},
) => {
  if (typeof value !== "number") {
    return options.fallback ?? "";
  }

  const truncated = truncate(value, { precision: options.precision });

  const currencyFormat = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: options.baseAssetSymbol ?? "BRL",
    maximumFractionDigits: options?.maximumFractionDigits ?? 10,
  }).format(truncated);

  if (options.baseAssetSymbol === "HCU") {
    return `${currencyFormat.replace("HCU", "")} HCU `;
  }

  return currencyFormat;
};
