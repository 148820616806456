interface Options {
  precision?: number;
}

export const truncate = (value: number | undefined, options: Options = {}) => {
  if (typeof value !== "number") return 0;

  const precision = options.precision ?? 2;
  const multiplier = Math.pow(10, precision);
  return Math.floor(value * multiplier) / multiplier;
};
